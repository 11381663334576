import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { ArrowForward } from '@mui/icons-material';
import homeImg from '../../../asset/img/welcome.png';


const HomeSection: React.FC = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    // Add code here to navigate to the welcome page
    navigate('/welcome');
  };

  return (
    <HomeWrapper>
      <HomeContent>
        <HomeTitle>
          <span style={{ wordBreak: 'keep-all' }}>내 몸의 에너지는 알고 지내자</span>
          <br />
          <span className="highlight">카이닥</span>
        </HomeTitle>
        <HomeSubtitle>카이닥은 개인의 몸 에너지를 평가하여 건강을 관리하는 솔루션입니다</HomeSubtitle>
        <CTAButton onClick={handleButtonClick}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div>내 몸 에너지 진단하러 가기</div>
            <div>Check My Body Energy</div>
          </div>
          <ArrowForward style={{ marginLeft: '1rem' }} sx={{ stroke: "#ffffff", strokeWidth: 1 }} />
        </CTAButton>
      </HomeContent>
      <HomeImage src={homeImg} alt="Home illustration" />
    </HomeWrapper>
  );
};

const HomeWrapper = styled.section`
  margin: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 2rem;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 55%;
  font: 700 16px 'Noto Sans', sans-serif;

  @media (max-width: 991px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const HomeTitle = styled.h1`
  color: #000;
  margin-bottom: 2rem;
  font-size: clamp(2rem, 5vw, 3rem);
  font-family: 'Gmarket Sans TTF', sans-serif;

  .highlight {
    color: #0f67fe;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    margin-bottom: 0;
    font-size: 2.5rem;
  }
`;

const HomeSubtitle = styled.p`
  color: #3d4966;
  text-align: center;
  font-weight: 400;
  font-size: 1.1rem;
  font-family: 'Noto Sans KR', sans-serif;
  opacity: 0;

  @media (max-width: 991px) {
    font-size: 1rem;
  }
`;

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 12px;
  background-color: #0f67fe;
  color: #fff;
  text-align: center;
  padding: 1rem 1.5rem;
  border: none;
  cursor: pointer;
  font-family: 'Noto Sans KR', sans-serif;

  @media (max-width: 991px) {
    font-size: 1rem;
  }
`;

const HomeImage = styled.img`
  width: 40%;

  @media (max-width: 991px) {
    display: none;
  }
`;

export default HomeSection;
